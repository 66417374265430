// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-current-projects-tsx": () => import("./../src/pages/currentProjects.tsx" /* webpackChunkName: "component---src-pages-current-projects-tsx" */),
  "component---src-pages-highlights-tsx": () => import("./../src/pages/highlights.tsx" /* webpackChunkName: "component---src-pages-highlights-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-projects-education-tsx": () => import("./../src/pages/Projects/education.tsx" /* webpackChunkName: "component---src-pages-projects-education-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../src/pages/Projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-marriage-tsx": () => import("./../src/pages/Projects/marriage.tsx" /* webpackChunkName: "component---src-pages-projects-marriage-tsx" */),
  "component---src-pages-projects-meal-isb-tsx": () => import("./../src/pages/Projects/mealISB.tsx" /* webpackChunkName: "component---src-pages-projects-meal-isb-tsx" */),
  "component---src-pages-projects-water-tsx": () => import("./../src/pages/Projects/water.tsx" /* webpackChunkName: "component---src-pages-projects-water-tsx" */),
  "component---src-templates-project-entry-tsx": () => import("./../src/templates/projectEntry.tsx" /* webpackChunkName: "component---src-templates-project-entry-tsx" */),
  "component---src-templates-project-type-tsx": () => import("./../src/templates/projectType.tsx" /* webpackChunkName: "component---src-templates-project-type-tsx" */)
}

